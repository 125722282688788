<template>
    <div id="user">
        <div class="tabs">
            <div v-for="item in tabs" :key="item.key" :class="['tab-item', active === item.key ? 'active' : '', item.disabled ? 'disabled' : '']" @click="handleSwitch(item)">
                {{ item.title }}
            </div>
        </div>
        <el-form v-show="active === 'profile'" :rules="rules" :model="formData" ref="form_ref" label-width="70px"  label-position="right" size="small">
            <el-form-item label="昵称" prop="nickname">
                <el-input style="width:180px"  size="small" v-model="formData.nickname" placeholder="请输入昵称"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="sex">
                <el-select size="small" v-model="formData.sex" placeholder="请选择性别">
                    <!-- <el-option label="保密" value="保密"></el-option> -->
                    <el-option label="男" :value="1"></el-option>
                    <el-option label="女" :value="0"></el-option>
                </el-select>
            </el-form-item>
            <!-- <el-form-item label="个人简介" prop="profile">
                <el-input type="textarea" v-model="formData.profile" placeholder="请输入简介" :autosize="{ minRows: 5 }"></el-input>
            </el-form-item> -->
            <!-- <el-form-item label=" ">
                <el-button class="submit" :disabled="disabled" @click="handleSubmit">保存</el-button> 
            </el-form-item> -->
        </el-form>
        <div v-show="active === 'userLogo'" class="user-logo">
            <div class="upload-wrapper">
                <div class="label">上传头像：</div>
                <el-upload class="upload" action="#" ref="upload_ref" :multiple="false" :http-request="handleFileImportUpload"
                    :file-list="fileList" :before-upload="beforeImportUpload"
                    accept=".bmp, .jpg, .png, .tif, .gif, .webp"
                >
                    <el-button ref="upload" slot="trigger" size="small" type="primary">选取文件</el-button>
                </el-upload>
            </div>
            <div class="tip">
                支持bmp、jpg、png、gif、webp格式图片。拖拽或缩放图中的虚线方格可调整头像，注意右侧小头像预览效果。
            </div>
            <div class="cropper-wrapper" :style='{width: `${imgObj.width}px`, height: `${imgObj.height}px`}'>
                <vueCropper
                    ref="cropper"
                    :img="option.img"
                    :outputSize="option.outputSize"
                    :outputType="option.outputType"
                    :canScale='option.canScale'
                    :autoCrop='option.autoCrop'
                    :autoCropWidth='option.autoCropWidth'
                    :autoCropHeight='option.autoCropHeight'
                    :canMoveBox='option.canMoveBox'
                    :canMove='option.canMove'
                    :centerBox='option.centerBox'
                    :info='option.info'
                    :fixedBox='option.fixedBox'
                    @realTime='realTime'
                ></vueCropper>
                <div class="preview">
                    <div>头像预览：</div>
                    <img :src='previewImg'  alt="" class='previewImg' ref="img">
                </div>
            </div>
        </div>
        <el-button :class="['submit', active]" :disabled="disabled" @click="handleSubmit">保存</el-button> 
    </div>
</template>
<script>
    export default {
        name: 'User',
        data () {
            return {
                active: 'profile',
                tabs: [
                    { title: '个人简介', key: 'profile' },
                    { title: '修改头像', key: 'userLogo' },
                    // { title: '个性网址', key: 'customSite', disabled: true },
                ],
                formData: {
                    id: '',
                    nickname: '',
                    sex: '',
                    avatar: ''
                },
                disabled: false,
                rules: {
                    nickname: [ { required: true, message: '请输入昵称' } ]
                },

                //
                fileList: [],
                option: {
                    img: '', // 裁剪图片地址
                    outputSize: 1, // 裁剪生成图片质量
                    outputType: 'jepg', // 裁剪生成图片格式
                    canScale: true, // 图片是否允许滚轮播放
                    autoCrop: true, // 是否默认生成截图框 false
                    info: false, // 是否展示截图框信息
                    autoCropWidth: 200, // 生成截图框的宽度
                    autoCropHeight: 200, // 生成截图框的高度
                    canMoveBox: true, // 截图框是否可以拖动
                    fixedBox: true, // 固定截图框的大小
                    canMove: false, // 上传图片是否可拖动
                    centerBox: true, // 截图框限制在图片里面
                },
                previewImg: null, // 预览后的图片
                previewObj: {
                    width: 200,
                    height: 200
                },
                imgObj: {
                    width: 200,
                    height: 200
                },
                fileBlob: null,
                file: null,
                filename: '',
                filetype: '',

            }
        },
        activated () {
            console.log('activated, user', this.userInfo);
            if (this.userInfo && this.userInfo.id){
                let { id, sex, nickname, avatar } = this.userInfo;
                this.formData = {
                    id,
                    sex,
                    nickname,
                };
                // if (avatar){
                //     let url = avatar.replace(/^\./g, '');
                //     if (!(url.startsWith('/'))) url = '/' + url;
                //     this.option.img = window.location.origin + url;
                //     console.log(this.option.img);
                // }
            }
            
        },
        computed: {
            userInfo(){
                return this.$store.state.userInfo;
            }
        },
        watch: {
            userInfo: function(val){
                // console.log('watch, userInfo', val);
                let { id, sex, nickname, avatar } = val;
                this.formData = {
                    id,
                    sex,
                    nickname,
                }
                // if (avatar){
                //     let url = avatar.replace(/^\./g, '');
                //     if (!(url.startsWith('/'))) url = '/' + url;
                //     this.option.img = window.location.origin + url;
                //     console.log(this.option.img);
                // }
                
            },
            'option.img': {
                handler: function (val) {
                    const that = this
                    const img = new Image()
                    img.src = val
                    img.onload  = function () {
                        that.imgObj.width = this.width
                        that.imgObj.height = this.height
                    }
                },
                immediate: true
            }
        },
        methods: {
            handleSwitch({ disabled, key }){
                if (disabled){
                    return this.$message({
                        type: 'info',
                        message: '功能暂未开放'
                    });
                }
                if (key === 'userLogo'){
                    this.fileList = [];
                    this.option = { ...this.$options.data().option };
                    this.previewImg = null;
                    this.previewObj = { ...this.$options.data().previewObj };
                    this.$nextTick(() => {
                        this.$refs.upload_ref.clearFiles();
                    })
                    
                }
                this.active = key;
            },
            beforeImportUpload(file) {
                console.log('beforeImportUpload', file);
                let reg = /.(bmp|jpg|png|gif|webp)$/;
                let flag = reg.test(file.name);
                if (!flag){
                    this.$message({
                        type: 'warning',
                        message: '只允许上传bmp、jpg、png、gif、webp图片'
                    });
                }
                return flag;
            },
            handleFileImportUpload(params) {
                // http://127.0.0.1:2333/api/knowledge/imageuploader
                this.filename = params.file.name;
                this.filetype = params.file.type;
                this.blobToDataURI(params.file,res => {
                    this.option.img = res;
                })
            },
            realTime (data) {
                this.$refs.cropper.getCropBlob(data => {
                    // 这里data数据为Blob类型，blobToDataURI方法转换成base64
                    // console.log(data)
                    this.blobToDataURI(data, res => {
                        // console.log(res)
                        this.previewImg = res;
                        this.fileBlob = data;
                    })
                })
            }, 
            blobToDataURI(blob, callback) {
                var reader = new FileReader();
                reader.readAsDataURL(blob);
                reader.onload = function (e) {
                    callback(e.target.result);
                }
            },
            // 点击保存 根据active不同 执行相应的方法
            handleSubmit(){
                if (this.disabled) return;
                // active   profile
                if (this.active === 'profile'){
                    this.handleSaveUserInfo();
                } else if(this.active === 'userLogo'){
                    // active   userLogo
                    this.handleImageUploader()
                } else {

                }
                
            },
            // 提交用户信息的更改
            handleSaveUserInfo(){
                // /api/user/edituserinfo
                this.api.postFormAPISM(
                    {
                        ...this.formData,
                    },
                    '/user/edituserinfo'
                ).then(async res => {
                    // console.log('edituserinfo');
                    this.$message({
                        type: 'success',
                        message: '修改成功'
                    });
                    this.$store.dispatch('getUserInfo');
                }).catch(error => {
                    console.log('error', error);
                })
            },
            // 将裁剪好的bolb转file并上传至服务器，然后调用保存用户头像接口
            handleImageUploader(){
                // let file = 
                // /api/article/imageuploader
                console.log('handleImageUploader', this.file, this.fileBlob, this.filetype);
                this.file = new File(
                    [this.fileBlob],
                    this.filename,
                    {
                        type: this.filetype,
                        lastModified: Date.now()
                    }
                );
                console.log('handleImageUploader', this.file);
                let data = new FormData();
                data.append('file', this.file);
                this.api.postFormDataAPISM(
                    data,
                    '/article/imageuploader'
                ).then(res => {
                    console.log('handleImageUploader', res.data.url);
                    // this.$store.dispatch('getUserInfo');
                    if (res.status == 200 && typeof res.data === 'object' && res.data.url){
                        this.handleSaveUserLogo(res.data.url);
                    }
                }).catch(error => {
                    console.log('error', error);
                })
            },
            // 调用保存用户头像接口
            handleSaveUserLogo(avatar){
                console.log('handleSaveUserLogo', avatar);
                // /api/user/edituserinfo
                this.api.postFormAPISM(
                    {
                        avatar,
                    },
                    '/user/edituserinfo'
                ).then(async res => {
                    // console.log('edituserinfo');
                    this.$message({
                        type: 'success',
                        message: '修改成功'
                    });
                    this.$store.dispatch('getUserInfo');
                }).catch(error => {
                    console.log('error', error);
                })
            }
        }
    }
</script>
<style lang="less">
    @import './index.less';
</style>